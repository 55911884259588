import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { User as UserInfo } from '@auth0/auth0-angular';
import validator from 'validator';

import { Invitation, Member, Role } from '@app/core/models';
import {
  Auth0Service,
  Auth0StoreService,
  ProgressService,
  OrganizationsApiService,
} from '@app/core/services';
import { SnackBarComponent } from '@app/shared/components';
import {
  ConfirmDialogComponent,
  DialogData as ConfirmDialogData,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-organization-members-setting',
  templateUrl: './organization-members-setting.component.html',
  styleUrls: ['./organization-members-setting.component.scss'],
})
export class OrganizationMembersSettingComponent implements OnInit {
  form! : UntypedFormControl;

  roles: Role[] = [];

  own!: UserInfo;

  userRole!: string | undefined;

  get members$() {
    return this.auth0StoreService.members$;
  }

  get invitations$() {
    return this.auth0StoreService.invitations$;
  }

  get members() {
    return this.auth0StoreService.membersSubject.getValue() || [];
  }

  get invitations() {
    return this.auth0StoreService.invitationsSubject.getValue() || [];
  }

  get adminNum() {
    return this.members.filter((member) => member.role.name === 'Admin').length;
  }

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private auth0Service: Auth0Service,
    private auth0StoreService: Auth0StoreService,
    private progressService: ProgressService,
    private organizationsApiService: OrganizationsApiService
  ) {}

  async ngOnInit() {
    this.form = this.fb.control({ email: '', role: 'Referencer' }, [
      this.validateDuplicatedEmail(),
      this.validateEmail(),
    ]);
    this.auth0StoreService.clear();
    this.own = await this.auth0Service.getOwnUserInfo();
    this.roles = await this.auth0Service.getRoles();
    await this.auth0StoreService.fetchMembers();
    await this.auth0StoreService.fetchInvitations();

    this.userRole = await firstValueFrom(this.auth0StoreService.currentUserRoleSubject);
  }

  getRoleFromLabel(roleLabel: string) {
    return this.roles.find((role) => role.name === roleLabel);
  }

  getRoleFromId(id: string) {
    return this.roles.find((role) => role.id === id);
  }

  // TODO: InputAndSelectComponentのoptionsのtextにラベル、valueにロールIDを渡すように修正して、この関数を使わないように修正する
  getMember() {
    const member = this.form.value as { email: string; role: string };
    return {
      email: member.email,
      role: this.getRoleFromLabel(member.role)?.id || '',
    };
  }

  validateDuplicatedEmail() {
    return (control: AbstractControl) => {
      const email = control.value.email;
      const duplicated =
        !!this.members?.find((member) => member.email === email) ||
        !!this.invitations?.find(
          (invitation) => invitation.invitee.email === email
        );
      return duplicated ? { duplicated: true } : null;
    };
  }

  // TODO: Angularのvalidatorsを使えるようにするため、emailとroleのFormControlをFormGroupに変更する
  validateEmail() {
    return (control: AbstractControl) => {
      const email = control.value.email;
      return !validator.isEmail(email) ? { email: true } : null;
    };
  }

  async createInvitation() {
    this.progressService.startProgress();

    try {
      const organizationId = await this.auth0Service.getOrganizationId();
      await this.auth0Service.createInvitation(
        organizationId,
        this.getMember()
      );
      await this.auth0StoreService.fetchInvitations();
    } catch (error) {
      this.progressService.endProgress();
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          failure: true,
          message: 'サーバーエラー',
        },
        duration: 3 * 1000,
      });
      return;
    }

    this.progressService.endProgress();
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: '招待メールを送信しました',
      },
      duration: 3 * 1000,
    });
    this.form.setValue({ email: '', role: '参照' });
    this.form.reset();
  }

  async confirmDeleteMember(member: Member & {role: Role}) {
    if(!this.canDeleteOrChange(member.role)) return;

    const data: ConfirmDialogData = {
      title: 'メンバーを削除しますか？',
      content: `${member?.name}はこの施設にアクセスできなくなります。この操作は元に戻せません。`,
      actions: ['キャンセル', '削除'],
      color: 'warn',
    };

    // 確認ダイアログ表示
    this.dialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.deleteMember(member.user_id);
        }
      });
  }

  async confirmDeleteInvitation(invitation: Invitation) {
    const data: ConfirmDialogData = {
      title: '招待を削除しますか？',
      content: `${invitation?.invitee.email}はこの施設に参加できなくなります。この操作は元に戻せません。`,
      actions: ['キャンセル', '削除'],
      color: 'warn',
    };

    // 確認ダイアログ表示
    this.dialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.deleteInvitation(invitation.id);
        }
      });
  }

  async deleteMember(userId: string) {
    // メンバーが1人の場合、メンバーを削除できないようにする
    if (!!this.members && this.members.length <= 1) {
      return;
    }

    try {
      const organizationId = await this.auth0Service.getOrganizationId();
      await this.organizationsApiService.deleteOrganizationUser(
        organizationId,
        userId
      );
      localStorage.removeItem('fundusApiCache');

      await this.auth0StoreService.fetchMembers();
      await this.auth0StoreService.fetchBelongedOrganizations();

      // 自分をメンバー削除する場合
      if (this.own.sub === userId) {
        location.reload();
        return;
      }

      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: 'メンバーを削除しました',
        },
        duration: 3 * 1000,
      });
    } catch (error) {
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          failure: true,
          message: 'サーバーエラー',
        },
        duration: 3 * 1000,
      });
    }
  }

  async deleteInvitation(invitationId: string) {
    try {
      await this.auth0Service.deleteInvitation(invitationId);
      await this.auth0StoreService.fetchInvitations();
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: '招待を削除しました',
        },
        duration: 3 * 1000,
      });
    } catch (error) {
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          failure: true,
          message: 'サーバーエラー',
        },
        duration: 3 * 1000,
      });
    }
  }

  async changeRole(userId: string, role: string, member: { role: string }) {
    try {
      await this.auth0Service.deleteRolesOfMember(userId, [role]);
      await this.auth0Service.addRolesOfMember(userId, [
        this.getRoleFromLabel(member.role)?.id || '',
      ]);
      localStorage.removeItem('fundusApiCache');

      await this.auth0StoreService.fetchMembers();
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: 'ロールを変更しました',
        },
        duration: 3 * 1000,
      });
    } catch (error) {
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          failure: true,
          message: 'サーバーエラー',
        },
        duration: 3 * 1000,
      });
    }
  }

  canDeleteOrChange(role: Role) {
    // 管理者が1人だけの場合はその管理者の削除、変更不可
    if(this.userRole !== 'Admin') {
      return false;
    } else if (role.name === 'Admin') {
      return this.adminNum > 1;
    }
    return true;
  }
}
