<div class="container">

    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title">ルックレック ファンダスについて</span>
    </div>
    <dl class="fundus-info">
      <dt>製造販売業者</dt>
      <dd>株式会社エムネス　広島県広島市南区東雲本町1-2-27</dd>

      <dt>名称</dt>
      <dd>ルックレック　ファンダス</dd>

      <dt>製造記号</dt>
      <dd>バージョン　1.1.26</dd>

      <dt>医療機器の別</dt>
      <dd>管理医療機器</dd>

      <dt>認証番号</dt>
      <dd>304AGBZX00076000　
        &nbsp;&nbsp;
        <a href="https://www.pmda.go.jp/PmdaSearch/kikiDetail/ResultDataSetPDF/171762_304AGBZX00076000_A_01_02" target="_blank">[ 添付文書 ]</a>

        </dd>
    </dl>


    <button class="basic close-button" mat-dialog-close
      [style.width]="'120px'"
      [style.height]="'40px'">
      閉じる
    </button>
  </div>
