<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
  (submit)="createInvitation()"
>
  <div fxLayout="column" fxLayoutGap="4px">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
      <app-input-and-select
        fxFlex
        [color]="form.dirty || form.errors ? 'light' : 'error'"
        placeholder="メールアドレスを入力してください"
        [options]="{'Referencer':'参照者', 'Diagnoser':'診断者', 'Editor':'編集者', 'Admin':'管理者'}"
        [formControl]="form"
        [disabled]="this.userRole !== 'Admin'"
        [matTooltip]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
      ></app-input-and-select>
      <app-custom-tooltip
        [tooltipMessage]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
        >
        <app-button
          color="primary"
          width="70px"
          height="25px"
          [disabled]="!!form.errors"
        >
          招待
        </app-button>
      </app-custom-tooltip>

    </div>
    <span class="error-message" *ngIf="form.hasError('duplicated')">
      このメールアドレスのユーザーは既に施設に登録されています
    </span>
    <span class="error-message" *ngIf="form.dirty && form.hasError('email')">
      メールアドレスの形式が不正です
    </span>
  </div>

  <div class="list" fxLayout="column" fxLayoutGap="4px">
    <div
      class="member"
      fxLayout="row"
      fxLayoutAlign=" center"
      fxLayoutGap="20px"
      *ngFor="let member of members$ | async"
    >
      <span class="name" fxFlex="30%">{{ member.name }}</span>
      <div
        fxLayout="row"
        fxLayoutAlign=" center"
        fxLayoutGap="8px"
        fxFlex="70%"
      >
        <app-input-and-select
          [ngModel]="{
            email: member.email,
            role: member.role.name || ''
          }"
          [ngModelOptions]="{ standalone: true }"
          [readonly]="true"
          [selectable]="canDeleteOrChange(member.role)"
          [hideDropdownIcon]="!canDeleteOrChange(member.role)"
          [options]="{'Referencer':'参照者', 'Diagnoser':'診断者', 'Editor':'編集者', 'Admin':'管理者'}"
          (ngModelChange)="
            changeRole(member.user_id, member.role.id || '', $event)
          "
          fxFlex
        ></app-input-and-select>
        <span [matTooltip]="this.userRole !== 'Admin' ? '操作権限なし' : ''">
          <mat-icon
            [class.disabled]="!canDeleteOrChange(member.role)"
            (click)="confirmDeleteMember(member)"
          >delete_outline</mat-icon
          >
        </span>

      </div>
    </div>

    <div
      class="invitation"
      fxLayout="row"
      fxLayoutAlign=" center"
      fxLayoutGap="20px"
      *ngFor="let invitation of invitations$ | async"
    >
      <span class="name" fxFlex="30%"></span>
      <div
        fxLayout="row"
        fxLayoutAlign=" center"
        fxLayoutGap="8px"
        fxFlex="70%"
      >
        <app-input-and-select
          [ngModel]="{
            email: invitation.invitee.email,
            role: getRoleFromId(invitation.roles[0])?.name
          }"
          [ngModelOptions]="{ standalone: true }"
          [readonly]="true"
          [hideDropdownIcon]="true"
          [selectable]="false"
          [options]="{'Referencer':'参照者', 'Diagnoser':'診断者', 'Editor':'編集者', 'Admin':'管理者'}"
          [opacity]="0.4"
          fxFlex
        ></app-input-and-select>
        <mat-icon (click)="confirmDeleteInvitation(invitation)"
          >delete_outline</mat-icon
        >
      </div>
    </div>
  </div>
</form>
